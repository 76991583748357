import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const Search = ({ callback }) => {
  const [query, setQuery] = useState("");
  return (
    <div
      style={{
        display: "flex",
        padding: 16,
        backgroundColor: "#FAFAFA",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      <input
        style={{
          flex: 1,
          fontSize: 16,
          padding: "8px 12px",
          borderStyle: "none",
          borderRadius: 8,
          outline: "none",
        }}
        placeholder="Ask your questions to AI"
        onChange={(event) => setQuery(event.target.value)}
        onKeyDown={(event) => {
          if(event.key === 'Enter') {
            callback(query);
          }
        }}
      />
      <button
        style={{
          marginLeft: 8,
          aspectRatio: "1",
          borderStyle: "none",
          borderRadius: 8,
          backgroundColor: "black",
          padding: 8,
        }}
        onClick={() => {
          callback(query);
        }}
      >
        <SearchIcon style={{ color: "white" }} />
      </button>
    </div>
  );
};

export default Search;
