import React from "react";
import useTimer from "../functions/useTimer";

const Timer = ({ title, startTime, endTime }) => {
  const { days, hours, minutes, seconds } = useTimer(startTime, endTime);

  const getS = (count) => {
    return count > 1 ? "s" : "";
  };

  return (
    <div
      style={{
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        display: "flex",
        flexDirection: "column",
        margin: 16,
      }}
    >
      <p
        style={{
          fontSize: 20,
          width: "100%",
          textAlign: "center",
          fontWeight: "bold",
          margin: 8,
        }}
      >
        {title}
      </p>
      <div
        style={{
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 32,
          paddingRight: 32,
        }}
      >
        <p
          style={{
            fontSize: 20,
            padding: 4,
            margin: "0px 8px 8px",
          }}
        >
          {days +
            ` Day` +
            getS(days) +
            ` ` +
            hours +
            ` Hour` +
            getS(hours) +
            ` ` +
            minutes +
            ` Minute` +
            getS(minutes) +
            ` ` +
            seconds +
            ` Second` +
            getS(seconds) +
            ` `}
        </p>
      </div>
    </div>
  );
};

export default Timer;
