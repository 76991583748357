import React from "react";

const Nav = ({ heading }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
      }}
    >
      <p
        style={{
          fontSize: 24,
          margin: 24,
        }}
      >
        {heading}
      </p>
    </div>
  );
};

export default Nav;
