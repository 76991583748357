import React from "react";
import "./App.css";
import Nav from "./components/Nav";
import Timer from "./components/Timer";
import Search from "./components/Search";

const App = () => {
  const redirectUser = (query) => {
    window.location = `https://www.perplexity.ai/?q=${query}`;
  };

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Nav heading={"daysleft.in"} />
        <p style={styles.paragraph}>
          <span style={styles.boldSpan}>daysLeft.in</span> is your ultimate
          source for tracking the days remaining until your{" "}
          <span style={styles.boldSpan}>JEE 2024</span> and{" "}
          <span style={styles.boldSpan}>NEET 2024</span> exams in India. Stay on top
          of your preparation with our accurate countdowns, empowering you to
          plan and succeed.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <div
            style={{
              padding: 8,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {/* JEE Mains 1*/}
            <Timer
              title={"Jee Mains - 1"}
              startTime={Date.now()}
              endTime={Date.parse("2024-01-23T23:59:59")}
            />
            {/* JEE Mains 2*/}
            <Timer
              title={"Jee Mains - 2"}
              startTime={Date.now()}
              endTime={Date.parse("2024-03-31T23:59:59")}
            />
          </div>
          <div
            style={{
              padding: 8,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {/* JEE Adv */}
            <Timer
              title={"Jee Advanced"}
              startTime={Date.now()}
              endTime={Date.parse("2024-06-03T23:59:59")}
            />
            {/* JEE Mains 1*/}
            <Timer
              title={"NEET"}
              startTime={Date.now()}
              endTime={Date.parse("2024-05-04T23:59:59")}
            />
          </div>
        </div>
        <p style={styles.paragraph}>
          Counting down the days to your{" "}
          <span style={styles.boldSpan}>JEE</span> and{" "}
          <span style={styles.boldSpan}>NEET</span> exams has never been easier.
          At <span style={styles.boldSpan}>daysLeft.in</span>, we're here to
          help you stay focused and motivated. Remember, every day is a step
          closer to your dreams, keep pushing, keep studying, and success will
          follow.
        </p>
      </div>
      <div style={{ position: "sticky", left: 0, right: 0, bottom: 0 }}>
        <Search callback={redirectUser} />
      </div>
    </div>
  );
};

const styles = {
  boldSpan: {
    fontWeight: "bold",
  },
  paragraph: {
    margin: 16,
    textAlign: "center",
    fontSize: 20,
  },
};

export default App;
